@use '../variable' as *;
@use '../mixin';

.gallery-view {
    @include mixin.default-flex(center, center);

    background-color: rgba(0, 0, 0, 0.75);
    color: $white;
    height: 100vh;
    position: fixed;
    width: 100%;
    z-index: 9999;

    .gallery-close {
        background: url('../img/close.png') no-repeat center;
        cursor: pointer;
        padding: mixin.em(30);
        position: absolute;
        right: 0;
        top: 0;
    }

    .gallery-previus,
    .gallery-next {
        cursor: pointer;
        display: block;
        height: 80vh;
        line-height: 80vh;
        margin-top: 10vh;
        position: absolute;
        top: 1em;
        width: 40vw;
        z-index: 9999;
    }

    .gallery-previus {
        background: url('../img/left-arrow.png') no-repeat 2% center;
        cursor: w-resize;
        left: 0;
        text-align: left;
    }

    .gallery-next {
        background: url('../img/right-arrow.png') no-repeat 98% center;
        cursor: e-resize;
        right: 0;
        text-align: right;
    }
}
