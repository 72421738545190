@media (min-width: 992px) {
    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: 8.333333%;
    }

    .col-lg-offset-2 {
        margin-left: 16.666667%;
    }

    .col-lg-offset-3 {
        margin-left: 25%;
    }

    .col-lg-offset-4 {
        margin-left: 33.333333%;
    }

    .col-lg-offset-5 {
        margin-left: 41.666667%;
    }

    .col-lg-offset-6 {
        margin-left: 50%;
    }

    .col-lg-offset-7 {
        margin-left: 58.333333%;
    }

    .col-lg-offset-8 {
        margin-left: 66.666667%;
    }

    .col-lg-offset-9 {
        margin-left: 75%;
    }

    .col-lg-offset-10 {
        margin-left: 83.333333%;
    }

    .col-lg-offset-11 {
        margin-left: 91.666667%;
    }
}
