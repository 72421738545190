@use '../variable' as *;
@use '../mixin';

.bg-second-color {
    background-color: $menu-item-border;
    padding: mixin.em(47) 0;

    .row {
        margin: 0;
    }
}

.timeline-facebook {
    margin-bottom: mixin.em(47);
    text-align: center;
}

.timeline-twitter {
    height: mixin.em(500);
    margin: auto;
    max-width: mixin.em(340);
    overflow-y: scroll;
}
