@use '../variable' as *;
@use '../mixin';

//
//	FORM
// ------------------------------------------------------
.form {
    abbr {
        display: none;
    }

    > label {
        line-height: 1.5em;
        margin: mixin.em(25) 0 0;
    }

    .show-abbr {
        abbr {
            color: $error;
            display: inline-block;
            font-weight: bolder;
            position: absolute;
            text-decoration: none;
            transform: translate(mixin.em(5), 0);
        }

        > label {
            line-height: 1.5em;
            margin: mixin.em(25) 0 0;

            &::after {
                content: ':';
            }
        }
    }

    input[type='email'],
    input[type='text'],
    input[type='number'],
    input[type='file'],
    textarea,
    select {
        width: 100%;
    }

    input,
    button,
    textarea,
    select {
        @include mixin.font-set(mixin.em(14), $title, 400);

        border: 1px solid $input-border;
        border-radius: mixin.em(4);
        font-family: $font-family;
        margin: 0;
        padding: mixin.em(8) mixin.em(16);
    }

    button {
        background-color: $form-submit;
        border: 1px solid $form-submit-border;
        border-radius: mixin.em(4);
        color: $white;
        cursor: pointer;
        display: block;
        margin: 0;
        padding: mixin.em(8) mixin.em(16);
        transition: all $animation-speed linear;

        &:hover {
            background-color: $form-submit-border;
        }
    }

    .errormessages {
        margin: mixin.em(4) 0 0;
        padding-left: 0;
        width: 100%;

        li {
            color: $error;
            line-height: 1;
            list-style-type: none;
            padding: 0 0 0 mixin.em(12);
        }
    }

    .text-danger {
        background-color: transparent;
        color: $error;
        display: block;
        margin: 0 0 0 mixin.em(12);
    }

    .checkbox {
        margin: mixin.em(25) 0 0;

        p {
            @include mixin.font-set(mixin.em(16), $text, 400);

            display: block;
            margin: mixin.em(8) 0 0;

            &::after {
                content: ':';
            }
        }

        label {
            display: block;
            line-height: 1.5;
            padding: 0.15em 0;
        }

        input {
            margin: 0;
        }
    }

    .control-label {
        @include mixin.font-set(mixin.em(16), $text, 600);

        display: block;
        margin: mixin.em(20) 0 mixin.em(5) 0;
    }

    .booleanfield {
        @include mixin.default-flex(baseline, flex-end);

        flex-direction: row-reverse;
        flex-wrap: wrap;
        margin: mixin.em(25) 0 0;

        label {
            &::after {
                content: '';
            }
        }

        input {
            margin: 0 mixin.em(5) 0 0;
        }
    }

    ul[id*='id_'] {
        margin: 0;
        padding: 0;

        li {
            list-style-image: none;
            list-style-type: none;
            margin-bottom: 0;
        }

        input {
            margin: 0;
        }

        label {
            line-height: 1;
        }
    }

    img.captcha {
        display: block;
        padding-bottom: mixin.em(5);
    }

    .help-text {
        font-size: 0.8em;
    }

    input[type='file'] {
        border: 0;
    }

    input[type=file].drag-and-drop {
        background-color: $menu-item-border;
        background-image: url('../img/upload-file.svg');
        background-position: 88% center;
        background-repeat: no-repeat;
        border: 2px dashed #ccc;
        border-radius: 0.2em;
        margin-bottom: 1em;
        padding: 2em;
        width: 100%;
    }

    .upload-file-frame {
        .fail-upload {
            color: $error;
            text-decoration: line-through;

            img {
                margin-right: 0.4em;
                position: relative;
                top: 4px;
            }
        }
    }

    img {
        margin: 0 0 0.2em;
    }

    input[name*='file'] {
        padding-left: 0;
    }
}
