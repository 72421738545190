@use '../variable' as *;
@use '../mixin';

//
//	PAGE - HOMEPAGE, BLOCK - BG-CZNIC
// ------------------------------------------------------
.bg-cznic {
    background-color: $cz-nic-primary;
    padding-bottom: mixin.em(1);
    padding-top: mixin.em(50);

    h2,
    p,
    a {
        color: $white;
    }

    .row {
        align-items: center;
    }
}
