@use '../variable' as *;
@use '../mixin';

.wood-board {
    position: relative;
}

.wood-board-hide-content {
    @include mixin.default-flex(center, center);

    background: url('../img/background-video.png');
    flex-direction: column;
    height: 150vh;
    overflow: hidden;
    transition: all 1s ease-in-out;

    video,
    iframe {
        width: 80%;
    }
}

.arrow-wrapper {
    bottom: 0;
    height: 94px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    transition: all 1s ease-in-out;
    width: 150px;

    .arrow-up-down {
        bottom: 20px;
        display: block;
        margin: auto;
        position: relative;
        transform: rotate(0deg);
    }

    .arrow-background {
        cursor: pointer;
        display: block;
        margin: auto;
        position: relative;
        top: 20px;
    }
}

.hide-wood-arrow ~ .arrow-wrapper {
    display: none;
}

.wood-board.board-hide {
    .wood-board-hide-content {
        height: 0;
    }

    .arrow-up-down {
        transform: rotate(180deg);
    }

    .arrow-wrapper {
        bottom: -24px;
        cursor: pointer;
        height: 75px;
        width: 100px;
    }
}
