@use '../variable' as *;
@use '../mixin';

//
//	FOOTER
// ------------------------------------------------------
footer {
    background: $menu-item-border;
    padding: mixin.em(20) 0;

    @media (max-width: $width-lg) {
        padding-bottom: mixin.em(30);
        padding-top: mixin.em(30);
    }

    .row {
        margin-bottom: 0;

        > div {
            flex: auto;
            width: auto;
            max-width: none;
            padding-left: inherit;
            padding-right: inherit;
        }
    }

    .row:first-of-type {
        @include mixin.default-flex();

        margin-left: mixin.em(1);
        margin-right: mixin.em(1);

        @media (max-width: $width-lg) {
            flex-direction: column;
        }

        div:first-of-type {
            @include mixin.default-flex(center, flex-start);

            @media (max-width: $width-lg) {
                justify-content: center;
            }

            @media (max-width: $width-md) {
                flex-direction: column;
            }

            img {
                transform: translate(mixin.em(2), mixin.em(3));

                @media (max-width: $width-md) {
                    transform: translate(0, 0);
                }
            }
        }

        .nav {
            @media (max-width: $width-lg) {
                width: 100%;
            }

            nav {
                @media (max-width: $width-lg) {
                    margin: auto;
                    text-align: center;
                }
            }
        }
    }

    li {
        display: inline-block;
        padding: mixin.em(16) mixin.em(13);
    }

    .nav-social {
        display: none;

        @media (max-width: $width-lg) {
            display: block;
            text-align: center;
        }

        li {
            @media (max-width: $width-md) {
                display: block;
                float: left;
                text-align: center;
                width: 50%;
            }
        }
    }

    .page-navigation {
        margin: 0;
        padding: 0;

        li:first-of-type {
            padding-left: 0;

            @media (max-width: $width-md) {
                padding: mixin.em(13);
            }
        }

        @media (max-width: $width-md) {
            li {
                display: block;
                padding: mixin.em(13);
                text-align: center;

                a {
                    border-bottom: 0;
                    display: inline-block;
                    padding: mixin.em(0) mixin.em(2.5);
                }
            }
        }
    }
}
