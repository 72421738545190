@use 'variable' as *;
@use 'mixin';

//
//	TYPOGRAPHY
// ------------------------------------------------------
h1,
h2,
h3 {
    font-family: $font-family-title;
    line-height: 1;
    margin: mixin.em(5) 0;
}

h1 {
    @include mixin.font-set(mixin.em(50), $primary, bold);

    line-height: mixin.em(60, 50);
    margin-bottom: mixin.em(22, 50);
    text-transform: uppercase;
}

h2 {
    @include mixin.font-set(mixin.em(40), $primary, bold);

    line-height: mixin.em(48, 40);
    word-break: break-word;
}

h3 {
    @include mixin.font-set(mixin.em(24), $primary, bold);

    line-height: mixin.em(30, 24);
}

h4 {
    @include mixin.font-set(mixin.em(16), $text, bold);

    line-height: mixin.em(24);
    margin: mixin.em(30) 0;
}

p {
    @include mixin.font-set(mixin.em(18), $text, normal);

    line-height: mixin.em(24, 16);
    margin: mixin.em(5) 0;
}

a {
    color: $primary;
    line-height: mixin.em(24, 16);
}

.text-bigger {
    p {
        font-size: mixin.em(24);
        line-height: mixin.em(32, 24);
    }
}

.text-center {
    text-align: center;
}

strong {
    font-weight: 700;
}
