@use '../variable' as *;
@use '../mixin';

//
//	FORM EMAIL
// ------------------------------------------------------
.form-email {
    @include mixin.default-flex(center, center);

    @media (max-width: $width-md) {
        flex-direction: column;
    }

    padding-top: mixin.em(40);

    input[type='email'] {
        margin: 0 mixin.em(5);
        width: mixin.em(310);
    }

    button {
        color: $white;
        margin: 0;

        @media (max-width: $width-md) {
            margin-top: mixin.em(16);
        }
    }

    .errormessages li {
        padding: 0 mixin.em(16);
    }
}
