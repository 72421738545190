@use 'variable' as *;
@use 'mixin';

//
//	GENERAL
// ------------------------------------------------------
html {
    box-sizing: border-box;
    color: $text;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

body {
    @include mixin.font-set(mixin.em(16), $text, 400);

    background-color: $primary-bg;
    color: $text;
    font-family: $font-family;
}

img {
    max-width: 100%;
}

.row {
    margin-bottom: mixin.em(50);
}

.narrow-article {
    p {
        margin: auto;
        max-width: $narrow-article;
    }
}

.img-md {
    img {
        height: $img-md;
    }
}

.arrow-before {
    a:first-of-type::before {
        content: url('../img/arrow.png');
        left: mixin.em(-15);
        position: relative;
        top: mixin.em(-38);
    }
}

.media-center {
    text-align: center;
}

.text-transform-none {
    h1,
    h2,
    p,
    div {
        text-transform: none;
    }
}

ul {
    list-style-position: inside;
    list-style-type: disc;
    margin: mixin.em(30) 0;
    padding-left: mixin.em(16);

    li {
        @include mixin.font-set(mixin.em(16), $text, normal);

        line-height: mixin.em(24, 16);
        margin: mixin.em(5) 0;
    }
}

/*
This narrow iframe in admin div.cms-modal-frame and div.cms-sideframe > div.cms-sideframe-frame

video,
iframe {
    margin-bottom: mixin.em(20);
    max-width: $width-lg;
    width: 100%;
}

iframe {
    height: 65vh;
}
*/

.col-lg-6 {
    iframe {
        height: mixin.em(304);
    }
}

.col-lg-4 {
    iframe {
        height: mixin.em(197);
    }
}
