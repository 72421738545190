@use '../variable' as *;
@use '../mixin';

.encyclopedia-submenu {
    margin-top: mixin.em(25);

    a {
        color: $text;
        font-size: mixin.em(16);
        font-weight: 700;
        line-height: mixin.em(24, 16);

        &[name] {
            color: $primary;
            text-decoration: none;
        }
    }

    li {
        &:nth-of-type(1),
        &:nth-of-type(5),
        &:nth-last-of-type(2) {
            margin-bottom: mixin.em(16);
        }
    }
}

.encyclopedia-content {
    h1 {
        text-transform: none;
    }

    p {
        margin: mixin.em(10) 0;

        &:first-of-type {
            font-size: mixin.em(24);
            line-height: mixin.em(32, 24);
        }
    }
}

.encyclopedia-desc {
    @include mixin.default-flex();

    background-color: $nav-bg;
    border: 1px solid $text;
    border-radius: mixin.em(5);
    margin: mixin.em(0.1);

    p:first-of-type {
        font-size: mixin.em(16);
        line-height: mixin.em(24, 16);
    }
}
