@use '../variable' as *;
@use '../mixin';

//
//	SOCIAL ICONS
// ------------------------------------------------------

footer .nav-social,
.page-header .nav-social {
    height: $img-xs;
    padding-bottom: mixin.em(40);

    @media (max-width: $two-rows) {
        margin: 0;
        padding-bottom: mixin.em(44);
    }

    @media (max-width: $width-lg) {
        display: none;
    }

    img {
        height: $img-xs;

        @media (max-width: $width-md) {
            height: $img-sm;
        }
    }

    li {
        display: inline-block;

        a {
            border-left: 0;
            padding: 0;
        }
    }
}
