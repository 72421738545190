@use '../variable' as *;
@use '../mixin';

//
//	PAGE - ABOUT TABLEXIA
// ------------------------------------------------------
.about {
    img {
        display: block;
        margin: auto;
    }
}

.gallery {
    @include mixin.default-flex(center, center);

    background-color: $gallery-bg;
    flex-wrap: wrap;
    margin: mixin.em(90) 0;
    padding: 0 mixin.em(20);

    img {
        cursor: pointer;
        max-height: 200px;
        object-fit: cover;
        padding: mixin.em(10);
        transform: scale(1);
        transition: transform 0.1 linear;
        width: 100%;

        @media (min-width: $width-sm) {
            max-height: 250px;
            width: 70%;
        }

        @media (min-width: $width-md) {
            max-height: 300px;
            width: 50%;
        }

        @media (min-width: $width-lg) {
            max-height: 350px;
            width: 25%;
        }

        &:hover {
            transform: scale(1.02);
        }
    }
}
