//
//	PAGE - MEDIA
// ------------------------------------------------------
.page-media {
    h1 {
        text-transform: none;
    }

    h4 {
        font-size: em(13);
        //@include font-set( em(13), $input-border, 400);

        line-height: em(24);

        a {
            font-size: em(16, 13);
            margin-right: em(16);
        }

        img {
            height: em(16);
        }
    }
}
