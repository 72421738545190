/*
    Colorize DjangCMS toolbar to detect the type of deployment.

    <body class="site-devel is-homepage">
        <div id="cms-top">
            <div class="cms-toolbar">
                <div class="cms-toolbar-left">
                    <div class="cms-toolbar-item cms-toolbar-item-logo">
                        <a>djangoCMS</a>
*/

@mixin toolbar($content, $color, $background_color) {
    div.cms-toolbar {
        background-color: $background_color;

        div.cms-toolbar-left {
            div.cms-toolbar-item-logo a {
                color: $color;

                &::after {
                    content: $content;
                }
            }
        }
    }
}

body.site-unknown div#cms-top {
    // E020 - puzzle
    @include toolbar(" \E020  Unknown", #6e6e6e, white);
}

body.site-devel div#cms-top {
    // E00C - eye
    @include toolbar(" \E00C  Devel", black, white);
}

body.site-test_r div#cms-top {
    // \E011 (i)
    @include toolbar(" \E011  Test R", #ff0000, #f6c393);
}

body.site-dev-nic-cz div#cms-top {
    // E012 - wheel
    @include toolbar(" \E012  Testing", #ff0000, #f6c393);
}

body.site-production div#cms-top {
    // E007 thick box
    @include toolbar(" \E007  Production!", #0e5d00, #9ff693);
}
