@use '../variable' as *;
@use '../mixin';
//
//	SITE HEADER
// ------------------------------------------------------

// navigation
.page-header {
    @include mixin.default-flex();

    background: $nav-bg;
    height: mixin.em(68);
    margin-bottom: mixin.em(50);

    @media (max-width: $two-rows) {
        height: auto;
    }

    @media (max-width: $width-md) {
        flex-direction: column;
        height: mixin.em(100);
    }

    li {
        display: inline-block;

        a {
            @include mixin.font-set(mixin.em(16), $text, bold);

            border-left: 2px solid $menu-item-border;
            padding: mixin.em(5);
            white-space: nowrap;

            &:hover,
            &:focus {
                color: $primary;
            }

            @media (max-width: $width-md) {
                border-left: 0;
            }
        }

        &:nth-of-type(1) a {
            border-left: 0;
        }
    }

    li.active a {
        color: $primary;
        text-decoration: underline;
    }
}

.left-header {
    @include mixin.default-flex();

    @media (max-width: $width-md) {
        width: 98%;
    }

    img {
        transform: translate(0, mixin.em(30));

        @media (max-width: $two-rows) {
            transform: translate(0, 0);
        }

        @media (max-width: $width-md) {
            max-width: 80%;
        }
    }
}

.page-navigation {
    @media (max-width: $two-rows) {
        margin: mixin.em(10) 0;
    }

    @media (max-width: $width-md) {
        li {
            display: block;

            a {
                border-bottom: 2px solid $menu-item-border;
                border-left: 0;
                display: block;
                padding: mixin.em(14) mixin.em(5);
            }
        }
    }
}

// select lang
.lang-social-container {
    @include mixin.default-flex();

    @media (max-width: $width-md) {
        flex-direction: column;
    }
}

.language-chooser {
    margin-right: mixin.em(10);

    @media (max-width: $two-rows) {
        margin: 0;
    }

    @media (max-width: $width-lg) {
        margin-bottom: mixin.em(16);
    }

    @media (max-width: $width-md) {
        @include mixin.default-flex(center, space-around);

        width: 100%;
    }
}

// control menu
@media (min-width: $width-md) {
    #toggleMenu {
        display: none;
    }
}

#closeMenu {
    display: none;
}

@media (max-width: $width-md) {
    #openMenu.hide-icon {
        display: none;
    }

    #closeMenu.show-icon {
        display: block;
    }
}

.right-header {
    @include mixin.default-flex();

    flex-wrap: wrap;
    margin: 0 mixin.em(20) 0 mixin.em(30);
    width: 100%;

    @media (max-width: $two-rows) {
        align-items: flex-start;
        flex-direction: column;
    }

    @media (max-width: $width-md) {
        background: $nav-bg;
        border-top: 2px solid $text;
        display: none;
        margin: 0;
        width: 100%;
    }
}

nav {
    @media (max-width: $width-md) {
        margin: auto;
        width: 90%;
    }
}

body.show-body {
    .right-header {
        @media (max-width: $width-md) {
            display: block;
            z-index: 99;
        }
    }

    .page-header {
        @media (max-width: $width-md) {
            height: 100vh;
            justify-content: flex-start;
        }
    }
}
