@function em($size, $context: 16) {
    @return calc($size / $context) + em;
}

@mixin font-size($size, $context: 16) {
    font-size: em($size, $context);
}

// --------------------------------------------------
//  default-flex - $align-items, $justify-content
// --------------------------------------------------
@mixin default-flex($align-items: center, $justify-content: space-between) {
    align-items: $align-items;
    display: flex;
    justify-content: $justify-content;
}

// --------------------------------------------------
// Font - size, color, weight
// --------------------------------------------------
@mixin font-set($fs, $c, $fw) {
    color: $c;
    font-size: $fs;
    font-weight: $fw;
    text-decoration: none;
}
