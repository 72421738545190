@use 'variable' as *;
@use 'mixin';

.games-statistics {
    h3 {
        margin-top: 1.2em;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: auto;
        max-width: 80vw;
        padding: 0;

        li {
            background: $nav-bg;
            border: 1px solid $primary-bg;
            display: inline-block;
            margin: 0;
            transition: background 0.33s, border 0.33s;
            width: 10em;

            &.checked {
                background-color: $selected-item;

                label {
                    font-weight: bold;
                }
            }
        }
    }

    .stats-last-game {
        th {
            font-weight: bold;
        }

        th,
        td {
            padding: 0.2em 0.4em;
            text-align: right;
        }
    }

    table.stats-fields {
        width: 100%;

        th {
            text-align: left;
            vertical-align: top;
            width: 8em;
        }

        th,
        td {
            border-bottom: 1px solid $menu-item-border;
            padding: 0.6em 0;
        }
    }

    table.submit-button {
        margin: 2em 0;
    }

    .main-form {
        p.datetime br {
            display: none;
        }
    }

    .tablexia-stat-formset {
        a.add-row,
        a.delete-row {
            @include mixin.font-set(mixin.em(10), $title, 400);

            background-color: $white;
            border: 1px solid $input-border;
            border-radius: mixin.em(4);
            font-family: $font-family;
            padding: mixin.em(8) mixin.em(16);
            text-decoration: none;
        }
    }

    ul.devices-groups li {
        padding-left: 0.4em;

        &:hover {
            background-color: $selected-item;
        }
    }
}

.calendarbox {
    z-index: 1000;

    .calendar caption {
        padding: 1em;
    }
}

.clockbox {
    z-index: 1000;

    h2 {
        background-color: $game-bg;
        font-size: 12pt;
        padding: 0.2em 0.6em;
    }
}
