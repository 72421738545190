@use 'variable' as *;

.djangocms-newsblog-content {
    margin: 0 14vw;
    padding: 2em 0;
}

.djangocms-newsblog-content {
    ul.pagination {
        li {
            display: inline-block;
            margin-right: 1em;

            a {
                border: 1px solid $title;
                display: inline-block;
                line-height: 1.55em;
                min-width: 1.55em;
                padding: 3px 5px;

                &:hover {
                    border: 1px solid $error;
                    color: $error;
                }
            }
        }

        li.active span {
            display: inline-block;
            line-height: 1.55em;
            min-width: 1.55em;
            text-align: center;
        }
    }
}
