//
//	VARIABLES
// ------------------------------------------------------
$font-size-base: 100%;
$font-family: 'Helvetica Neue', helvetica, arial;
$font-family-title: 'Comenia Sans', 'Arial';

$nav-bg: #ead4b7;
$text: #420;
$primary-bg: #f1ede7;
$primary: #38c;
$menu-item-border: #d3c9bc;
$white: #fff;
$title: #333;
$cz-nic-primary: #1e3e88;
$selected-item: #f3af56;
$gallery-bg: #292933;
$game-bg: #f5dd5d;

// Error messages:
$info: #808080;
$warning: #d38003;
$success: #006e00;
$error: #be0202;

$input-border: #98918d;
$form-submit: #dd5c1b;
$form-submit-border: #843703;

$debug: false;
$enable-rounded: true;
$animation-speed: 0.25s;

//layout
$width-sm: 400px;
$width-md: 768px;
$width-lg: 1055px;

//image
$img-xs: 30px;
$img-sm: 40px;
$img-md: 64px;
$im-lg: 80px;

//menu
$two-rows: 1800px;
$narrow-article: 600px;
